export default {
  en: {
    dashboardBanner: {
      title: 'External Monitoring',
      comingSoon: 'Coming soon',
      text: "Gain real-time insights on your suppliers' security posture, empowering collaboration and informed decision-making across your entire supply chain",
      cta: 'Register your interest',
      thanks: 'Thanks for your interest',
      toggleAnimation: 'Toggle animation',
    },
    supplierMonitoringInterest: {
      pageTitle: 'External Monitoring',
      title: 'External Monitoring',
      text: 'Gain real-time, actionable insights, allowing you to visualise your external security posture',
      cta: 'Register your interest',
      thanks: 'Thanks for your interest',
    },
    menu: {
      scans: 'Scans',
      assets: 'Domains',
    },
    categories: {
      emailSecurity: 'Email Security',
      webSecurity: 'Web Security',
    },
    categoryDescription: {
      emailSecurity:
        'Protect domains from email spoofing and phishing through email authentication methods such as SPF, DKIM, and DMARC. These controls verify email legitimacy and build sending reputation.',
      webSecurity:
        'Protect domains from attacks and data theft through secure connections and content controls such as TLS, security headers, and content policies. These configurations ensure secure content delivery and prevent malicious code.',
    },
    statusIcon: {
      altText: {
        good: 'Low',
        okay: 'Medium',
        bad: 'High/Critical',
      },
    },
    supplierMonitoring: {
      title: 'External Monitoring',
      beta: 'Beta version: This is an early release and features may change based on feedback.',
      assetStatus: {
        ACTIVE: 'Active',
        DISABLED: 'Inactive',
        UNREACHABLE: 'Unreachable',
        label: 'Status',
      },
      scanSeverity: {
        CRITICAL: 'Critical',
        HIGH: 'High',
        MEDIUM: 'Medium',
        LOW: 'Low',
        label: 'Severity',
      },
      activeAssets: 'Active Domains',
      activeAssetsDescription:
        'Regular security monitoring is running for these domains. Security checks include email authentication and web security configuration.',
      disabledAssets: 'Inactive Domains',
      disabledAssetsDescription:
        'Security monitoring is paused or unavailable for these domains. This could be due to manual pause or connection issues.',
      scanError: 'Scan error',
      scanErrorFull: 'Cannot scan domain. Please verify the domain name is correct.',
      createdAt: 'Added',
      createdBy: 'Added by',
      lastModifiedAt: 'Updated at',
      lastModifiedBy: 'Updated by',
      editAssetButton: 'Edit domain',
      addAssetButton: 'Add domain',
      addAssetModalTitle: 'Add domain',
      editAssetModalTitle: 'Edit domain',
      assetValue: 'Domain name',
      assetValuePlaceholder: 'example.com',
      assetValueHelpText: `Enter the domain name without 'www' or 'http://`,
      assetDescription: 'Description (optional)',
      assetDescriptionPlaceholder: 'Production website',
      assetDescriptionHelpText: 'Add any notes that help identify this domain',
      saveAssetButton: 'Save domain',
      saveAssetAndRescanButton: 'Update and rescan',
      deleteAsset: 'Delete domain',
      deleteAssetSuccess: 'Asset deleted successfully',
      deleteAssetError: 'Error deleting domain',
      editAssetError: 'Error saving domain',
      addAssetError: 'Error adding domain',
      addAssetSuccess: 'Asset added successfully',
      saveAssetSuccess: 'Asset saved successfully',
      rescanAssetError: 'Error triggering domain re-scan',
      assetCreatedBySystem: 'System',
      cancelButton: 'Cancel',
      emptyTitle: 'You haven’t added any domains yet',
      emptySubtitle: 'Add your first domain so we can display ',
      emptyCTA: 'Add a Domain',
    },
    supplierMonitoringScans: {
      emptyTitle: 'You don’t have any scans yet',
      emptySubtitle: 'You will need to add a domain before we can display any scans',
      emptyCTA: 'Add a Domain',
      feedbackTitle: 'Give us your feedback',
      feedbackText:
        'We are still building out this feature and would love to hear how you’re finding it.',
      feedbackCTA: 'Send us feedback',
    },
    scanResult: {
      scanDescriptionTitle: 'What does this scan type check for?',
      scanLearnMore: 'Learn more about this scan',
      scanTitle: {
        SPF: 'Are SPF records protecting the email domain?',
        DKIM: "Is DKIM protecting the domain's emails from tampering?",
        DMARC: "Is DMARC enforcing the domain's email security policies?",
        HSTS: 'Is HSTS enforcing secure connections to your domain?',
        CSP: 'Is the Content Security Policy (CSP) protecting the domain from malicious code?',
        TLS: 'Is TLS configured securely on the domain?',
        SecurityHeaders: 'Are HTTP security headers protecting the domain?',
      },
      scanDescription: {
        SPF: 'SPF (Sender Policy Framework) helps prevent email spoofing by specifying which servers can send email from your domain. Your SPF records need to list all legitimate email sending services while blocking unauthorised senders.',
        DKIM: 'We monitor your DKIM (DomainKeys Identified Mail) configuration to verify your emails are digitally signed and protected against tampering.',
        DMARC:
          'We monitor your DMARC (Domain-based Message Authentication, Reporting, and Conformance) configuration to ensure you have a strong policy that tells receiving servers how to handle suspicious emails from your domain.',
        HSTS: 'We monitor your HSTS (HTTP Strict Transport Security) configuration to ensure browsers only connect to your domain using secure HTTPS connections.',
        CSP: 'We monitor your CSP (Content Security Policy) configuration to ensure your website only loads resources from trusted sources and prevents code injection attacks.',
        TLS: "Transport Layer Security (TLS) creates an encrypted connection between your website and its visitors. We monitor your TLS configuration to ensure it's properly securing data in transit.",
        SecurityHeaders:
          "HTTP security headers tell browsers how to handle your website's content securely. We monitor these headers to ensure they're properly configured to prevent common web attacks.",
      },
      error: 'Unable to scan asset',
      unknownAsset: 'Unknown asset',
      scan: 'Scan: ',
      expandScan: 'Expand scan information',
      findingsCount: '{n} finding | {n} findings',
      noFindings: 'There were no findings',
      lastScanned: 'Last scanned:',
      scanInProgress: 'Scan in progress:',
      scanInProgressText: 'This may take a few minutes',
      rescan: 'Scan now',
      findings: 'Findings',
      criticality: 'Criticality',
    },
  },
};
